export const state = () => ({
  isOverlayActive: false,
  isModalActive: false,
  modalContent: Object,
  isPageTransitionActive: false,
  layout: '',
  isMusicActive: false
});

export const mutations = {
  toggleOverlay(state, value) {
    state.isOverlayActive = value;
  },
  toggleMusic(state, value) {
    state.isMusicActive = value;
  },
  setModalContent(state, value) {
    state.modalContent = value;
  },
  toggleModal(state, value) {
    state.isModalActive = value;
  },
  startPageTransitionAnimation(state) {
    state.isPageTransitionActive = true;
  },
  stopPageTransitionAnimation(state) {
    state.isPageTransitionActive = false;
  },
  setPageLayout(state, layout) {
    state.layout = layout;
  }
};

export const getters = {
  isOverlayActive(state) {
    return state.isOverlayActive;
  },
  isMusicActive(state) {
    return state.isMusicActive;
  },
  isModalActive(state) {
    return state.isModalActive;
  },
  getModalContent(state) {
    return state.modalContent;
  },
  isLoaderActive(state) {
    return state.isLoaderActive;
  },
  isPageTransitionActive(state) {
    return state.isPageTransitionActive;
  },
  getPageLayout(state) {
    return state.layout;
  }
};
