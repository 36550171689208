import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=3f85e511&scoped=true&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=3f85e511&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f85e511",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/vercel/path0/components/elements/Heading.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default})
