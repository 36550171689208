
import { mapGetters } from 'vuex';

export default {
  name: 'FooterComponent',
  data() {
    return {
      clicked: false
    };
  },
  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive',
      siteParts: 'settings/siteParts'
    })
  }
};
