
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      translations: 'page/getTranslations'
    }),
    pageTranslationsUrls() {
      if (!this.translations) {
        return [];
      }

      return this.$i18n.locales.map((locale) => {
        const { url, isHome } = this.translations[locale.code] || {};
        // if no slug is set, then redirect to homepage of same language
        const slug = url?.fullPath ? `${isHome ? '' : '/'}${url?.fullPath}` : '/';
        return {
          code: locale.code,
          slug
        };
      });
    }
  }
};
