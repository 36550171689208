export const SanityContent = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-content.js' /* webpackChunkName: "components/sanity-content" */).then(c => wrapFunctional(c.default || c))
export const SanityFile = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-file.js' /* webpackChunkName: "components/sanity-file" */).then(c => wrapFunctional(c.default || c))
export const SanityImage = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-image.js' /* webpackChunkName: "components/sanity-image" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn = () => import('../../components/elements/Animations/CustomFadeIn.vue' /* webpackChunkName: "components/animation-custom-fade-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn2 = () => import('../../components/elements/Animations/CustomFadeIn2.vue' /* webpackChunkName: "components/animation-custom-fade-in2" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFlagIn = () => import('../../components/elements/Animations/CustomFlagIn.vue' /* webpackChunkName: "components/animation-custom-flag-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationGsapFadeInExample = () => import('../../components/elements/Animations/GsapFadeIn_Example.vue' /* webpackChunkName: "components/animation-gsap-fade-in-example" */).then(c => wrapFunctional(c.default || c))
export const AnimationGsapStretchXTranslate = () => import('../../components/elements/Animations/GsapStretchXTranslate.vue' /* webpackChunkName: "components/animation-gsap-stretch-x-translate" */).then(c => wrapFunctional(c.default || c))
export const DocumentErrorPage = () => import('../../components/document/ErrorPage.vue' /* webpackChunkName: "components/document-error-page" */).then(c => wrapFunctional(c.default || c))
export const DocumentPage = () => import('../../components/document/Page.vue' /* webpackChunkName: "components/document-page" */).then(c => wrapFunctional(c.default || c))
export const PageCookie = () => import('../../components/page/Cookie.vue' /* webpackChunkName: "components/page-cookie" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/page/Footer.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/page/Header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/page/Loader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const PagePreFooter = () => import('../../components/page/PreFooter.vue' /* webpackChunkName: "components/page-pre-footer" */).then(c => wrapFunctional(c.default || c))
export const PageScrollTop = () => import('../../components/page/ScrollTop.vue' /* webpackChunkName: "components/page-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const PageTransitionElements = () => import('../../components/page/TransitionElements.vue' /* webpackChunkName: "components/page-transition-elements" */).then(c => wrapFunctional(c.default || c))
export const ContentCurriculum = () => import('../../components/content/Curriculum.vue' /* webpackChunkName: "components/content-curriculum" */).then(c => wrapFunctional(c.default || c))
export const ContentExampleWithPicture = () => import('../../components/content/ExampleWithPicture.vue' /* webpackChunkName: "components/content-example-with-picture" */).then(c => wrapFunctional(c.default || c))
export const ContentFaq = () => import('../../components/content/Faq.vue' /* webpackChunkName: "components/content-faq" */).then(c => wrapFunctional(c.default || c))
export const ContentFileDownload = () => import('../../components/content/FileDownload.vue' /* webpackChunkName: "components/content-file-download" */).then(c => wrapFunctional(c.default || c))
export const ContentForm = () => import('../../components/content/Form.vue' /* webpackChunkName: "components/content-form" */).then(c => wrapFunctional(c.default || c))
export const ContentHomeHero = () => import('../../components/content/HomeHero.vue' /* webpackChunkName: "components/content-home-hero" */).then(c => wrapFunctional(c.default || c))
export const ContentIframeVideo = () => import('../../components/content/IframeVideo.vue' /* webpackChunkName: "components/content-iframe-video" */).then(c => wrapFunctional(c.default || c))
export const ContentImageWithCaption = () => import('../../components/content/ImageWithCaption.vue' /* webpackChunkName: "components/content-image-with-caption" */).then(c => wrapFunctional(c.default || c))
export const ContentMediaList = () => import('../../components/content/MediaList.vue' /* webpackChunkName: "components/content-media-list" */).then(c => wrapFunctional(c.default || c))
export const ContentMedicoSearch = () => import('../../components/content/MedicoSearch.vue' /* webpackChunkName: "components/content-medico-search" */).then(c => wrapFunctional(c.default || c))
export const ContentMultimedia = () => import('../../components/content/Multimedia.vue' /* webpackChunkName: "components/content-multimedia" */).then(c => wrapFunctional(c.default || c))
export const ContentMusicPlayer = () => import('../../components/content/MusicPlayer.vue' /* webpackChunkName: "components/content-music-player" */).then(c => wrapFunctional(c.default || c))
export const ContentNewsList = () => import('../../components/content/NewsList.vue' /* webpackChunkName: "components/content-news-list" */).then(c => wrapFunctional(c.default || c))
export const ContentSlideshow = () => import('../../components/content/Slideshow.vue' /* webpackChunkName: "components/content-slideshow" */).then(c => wrapFunctional(c.default || c))
export const ContentTeam = () => import('../../components/content/Team.vue' /* webpackChunkName: "components/content-team" */).then(c => wrapFunctional(c.default || c))
export const ContentTeaser = () => import('../../components/content/Teaser.vue' /* webpackChunkName: "components/content-teaser" */).then(c => wrapFunctional(c.default || c))
export const ContentTestimonial = () => import('../../components/content/Testimonial.vue' /* webpackChunkName: "components/content-testimonial" */).then(c => wrapFunctional(c.default || c))
export const ContentTextBlock = () => import('../../components/content/TextBlock.vue' /* webpackChunkName: "components/content-text-block" */).then(c => wrapFunctional(c.default || c))
export const ContentTypographySetup = () => import('../../components/content/TypographySetup.vue' /* webpackChunkName: "components/content-typography-setup" */).then(c => wrapFunctional(c.default || c))
export const ContentVideo = () => import('../../components/content/Video.vue' /* webpackChunkName: "components/content-video" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/elements/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const FieldDatePicker = () => import('../../components/elements/FieldDatePicker.vue' /* webpackChunkName: "components/field-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FieldPhoneNumber = () => import('../../components/elements/FieldPhoneNumber.vue' /* webpackChunkName: "components/field-phone-number" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/elements/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitch = () => import('../../components/elements/LanguageSwitch.vue' /* webpackChunkName: "components/language-switch" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/elements/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const MenuOverlay = () => import('../../components/elements/MenuOverlay.vue' /* webpackChunkName: "components/menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/elements/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationTree = () => import('../../components/elements/NavigationTree.vue' /* webpackChunkName: "components/navigation-tree" */).then(c => wrapFunctional(c.default || c))
export const Picture = () => import('../../components/elements/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c))
export const Richtext = () => import('../../components/elements/Richtext.vue' /* webpackChunkName: "components/richtext" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/elements/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SocialMedia = () => import('../../components/elements/SocialMedia.vue' /* webpackChunkName: "components/social-media" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/elements/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/elements/AccordionGroup/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionGroup = () => import('../../components/elements/AccordionGroup/index.vue' /* webpackChunkName: "components/accordion-group" */).then(c => wrapFunctional(c.default || c))
export const ModalContent = () => import('../../components/elements/Modal/ModalContent.vue' /* webpackChunkName: "components/modal-content" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/elements/Modal/index.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
