const sanityClient = require('@sanity/client');

export default async function ({ route, isHMR, $config, store }) {
  try {
    if (isHMR) { return; }
    const {
      query: { preview, documentId }
    } = route;

    if (!preview) {
      store.commit('preview/setPreviewMode', false);
      return;
    }

    const client = sanityClient({
      projectId: $config.api.projectId,
      dataset: $config.api.dataset,
      apiVersion: $config.api.apiVersion,
      useCdn: false,
      token:
        // eslint-disable-next-line
        'skV9UiUs8yYbstX2HpoLyIDNqf4bwKOiQi4ApRKQeyChYmxqNp7lch9ZhwLbHyS3EJE8QX36ZahxOq5TqUhusrGmTPpEveLYUT8vjqbdaOqT9qlXtrZC90AJIOMJbLgJcx54l3od9nxzMRXHW89ueLy8e9WlyIcqqDkJrhsQWOhxFvkho05N'
    });

    const query = `*[_id=="${documentId}"][0]`;
    const result = await client.fetch(query);
    store.commit('preview/setPreviewMode', true);
    store.commit('preview/setDocument', result || {});
  } catch (e) {
    throw new Error(`previewMode.js: ${e}`);
  }
}
