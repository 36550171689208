
import { mapGetters } from 'vuex';
import SvgPlay from '../../assets/svg/play.svg';
import SvgPause from '../../assets/svg/pause.svg';
import sound from '../../assets/mememusic.MP3';

export default {
  components: {
    SvgPlay,
    SvgPause
  },
  data() {
    return {
      audio: null,
      isPlaying: false
    };
  },
  computed: {
    ...mapGetters({
      isMusicActive: 'ui/isMusicActive'
    })
  },
  methods: {
    toggleAudio() {
      if (!this.audio) {
        this.audio = new Audio(sound);
      }

      if (this.isPlaying) {
        this.audio.pause();
        this.$store.commit('ui/toggleMusic', false);
      } else {
        this.audio.play();
        this.$store.commit('ui/toggleMusic', true);
      }

      this.isPlaying = !this.isPlaying;
    },
    beforeDestroy() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    }
  }
};
